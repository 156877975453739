import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Wrapper, LogoContainer, ButtonContainer, Desktop, LinkContainer, IconsContainer } from './Navbar.styled';
import logoImg from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'parameters';
import { AuthorizationContext } from 'context';
import { BytegaButton } from 'components';
import { useTranslation } from 'react-i18next';
import { Button, Container, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';

type Props = {};

export function Navbar(props: Props) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { isAuth, logoutUser } = useContext(AuthorizationContext);

    const theme = useTheme();

    const [languageAnchorEl, setLanguageAnchorEl] = useState<null | HTMLElement>(null);

    const handleLanguageClick: any = (event: React.MouseEvent<HTMLElement>) => {
        setLanguageAnchorEl(event.currentTarget);
    };

    const handleLanguageClose = () => {
        setLanguageAnchorEl(null);
    };

    const changeLanguage = (lng: string) => {
        localStorage.setItem('i18nextLng', lng);
        i18n.changeLanguage(lng);
        handleLanguageClose();
    };

    const open = Boolean(languageAnchorEl);

    return (
        <Container>
            <Wrapper>
                <LinkContainer>
                    <LogoContainer>
                        <Link to='/'>
                            <img src={logoImg} alt='logo' />
                        </Link>
                    </LogoContainer>
                </LinkContainer>

                <ButtonContainer>
                    <BytegaButton
                        variant='text'
                        size='large'
                        onClick={() => navigate(ROUTES.services)}
                        text={t('Services')}
                    />

                    {isAuth ? (
                        <>
                            <BytegaButton
                                variant='contained'
                                size='large'
                                onClick={() => navigate(ROUTES.app)}
                                text={t('Dashboard')}
                            />

                            <BytegaButton
                                variant='text'
                                size='large'
                                onClick={() => {
                                    logoutUser();
                                }}
                                text={t('Log out')}
                            />
                        </>
                    ) : (
                        <>
                            <BytegaButton
                                variant='text'
                                size='large'
                                onClick={() => navigate(ROUTES.login)}
                                text={t('Log in')}
                            />
                            <BytegaButton
                                variant='contained'
                                size='large'
                                onClick={() => navigate(ROUTES.register)}
                                text={
                                    <>
                                        {t('Sign up')}
                                        <Desktop>&nbsp;{t('for free')}</Desktop>
                                    </>
                                }
                            />
                        </>
                    )}
                    <Button
                        aria-controls={open ? 'language-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        variant='text'
                        size='large'
                        onClick={handleLanguageClick}
                        sx={{
                            borderRadius: '20px',
                            height: '32px',
                            width: '32px'
                        }}>
                        <TranslateIcon
                            sx={{
                                height: '32px',
                                width: '32px',
                                padding: '8px'
                            }}
                        />
                    </Button>
                    <Menu
                        id={'language-menu'}
                        anchorEl={languageAnchorEl}
                        keepMounted
                        open={Boolean(languageAnchorEl)}
                        onClose={handleLanguageClose}>
                        <MenuItem onClick={() => changeLanguage('hr')} selected={i18n.language === 'hr'}>
                            {t('language.Croatian')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => changeLanguage('en')}
                            sx={{
                                height: '56px'
                            }}
                            selected={i18n.language === 'en'}>
                            {t('language.English')}
                        </MenuItem>
                    </Menu>
                </ButtonContainer>
            </Wrapper>
        </Container>
    );
}
