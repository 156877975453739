import { BytegaButton, HomeServices, PublicWrapper } from 'components';
import { useContext, useRef } from 'react';
import { ButtonContainerHero, HeroCallToAction, HomeHero } from './Home.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'parameters';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { ButtonContainer, Desktop } from 'components/navbar/Navbar.styled';
import { AuthorizationContext } from 'context';

type Props = {};

export function Home(props: Props) {
    let { link } = useParams();
    const navigate = useNavigate();
    const serviceRef = useRef(null) as any;
    const { t } = useTranslation();

    const { isAuth, logoutUser } = useContext(AuthorizationContext);

    const title = t('homePage.title');
    const subtitle = t('homePage.subtitle');
    const description = t('homePage.description');

    if (!!link) {
        if (link === 'services' && !!serviceRef) {
            window.scrollTo({
                top: serviceRef?.current?.offsetTop - 81,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            navigate(ROUTES.notdefined);
        }
    }

    return (
        <PublicWrapper>
            <>
                <Container>
                    <HomeHero>
                        <Typography variant='h2' component='h1'>
                            {title}
                        </Typography>
                        <Typography variant='h4' component='h2'>
                            {subtitle}
                        </Typography>

                        <HeroCallToAction>
                            <Typography variant='body1'>{description}</Typography>
                            <ButtonContainerHero>
                                {isAuth ? (
                                    <>
                                        <BytegaButton
                                            variant='contained'
                                            size='large'
                                            onClick={() => navigate(ROUTES.app)}
                                            text={t('Dashboard')}
                                        />

                                        <BytegaButton
                                            variant='text'
                                            size='large'
                                            onClick={() => {
                                                logoutUser();
                                            }}
                                            text={t('Log out')}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <BytegaButton
                                            variant='contained'
                                            size='large'
                                            onClick={() => navigate(ROUTES.register)}
                                            text={
                                                <>
                                                    {t('Sign up')}
                                                    <Desktop>&nbsp;{t('for free')}</Desktop>
                                                </>
                                            }
                                        />
                                        <BytegaButton
                                            variant='text'
                                            size='large'
                                            onClick={() => navigate(ROUTES.login)}
                                            text={t('Log in')}
                                        />
                                    </>
                                )}
                            </ButtonContainerHero>
                        </HeroCallToAction>
                    </HomeHero>
                </Container>
                <HomeServices serviceRef={serviceRef} />
            </>
        </PublicWrapper>
    );
}
