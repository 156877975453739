import React from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BytegaButton, HomeServiceCard } from 'components';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

type Props = {
    serviceRef: any;
};

export function HomeServices(props: Props) {
    const { serviceRef } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <div ref={serviceRef}>
            <Container
                sx={{
                    background:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('frontPageImages/service_bg.png')lightgray 50% / cover no-repeat",
                    backgroundSize: 'cover',
                    borderRadius: '40px',
                    padding: '40px 0',
                    marginY: '40px'
                }}>
                <Typography gutterBottom variant='h3' component='h2' sx={{ color: theme.palette.primary.contrastText }}>
                    {t('homePageService.PageTitle')}
                </Typography>

                <Grid container direction='column' alignItems='left' py={12} gap={8}>
                    <Grid item container direction='row' justifyContent='flex-end' alignItems='center' spacing={8}>
                        <Grid item>
                            <HomeServiceCard
                                title={t('Cargo')}
                                description={t('homePageService.CargoDesc')}
                                icon={
                                    <Inventory2OutlinedIcon
                                        sx={{
                                            backgroundColor: theme.palette.primary.contrastText,
                                            borderRadius: '50%',
                                            height: '32px',
                                            width: '32px',
                                            padding: '8px'
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                    <Grid item container direction='row' justifyContent='flex-start' alignItems='center' spacing={8}>
                        <Grid item xs={2} />
                        <Grid item>
                            <HomeServiceCard
                                title={t('Transport')}
                                description={t('homePageService.TransportDesc')}
                                icon={
                                    <LocalShippingOutlinedIcon
                                        sx={{
                                            backgroundColor: theme.palette.primary.contrastText,
                                            borderRadius: '50%',
                                            height: '32px',
                                            width: '32px',
                                            padding: '8px'
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' justifyContent='flex-end' alignItems='center' spacing={8}>
                        <Grid item>
                            <HomeServiceCard
                                title={t('Storage')}
                                description={t('homePageService.StorageDesc')}
                                icon={
                                    <WarehouseOutlinedIcon
                                        sx={{
                                            backgroundColor: theme.palette.primary.contrastText,
                                            borderRadius: '50%',
                                            height: '32px',
                                            width: '32px',
                                            padding: '8px'
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={2} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
