import { styled } from '@mui/material';
import { sizes } from 'parameters';

export const PageWrapper = styled('div')(({ theme }) => ({}));

export const MainWrapper = styled('main')(({ theme }) => ({
    minHeight: 'calc(100vh - 230px)'
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
    borderRadius: '8px',
    maxWidth: '906px',
    maxHeight: 'calc(100vh - 230px)',
    overflowY: 'auto',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
        padding: '24px'
    }
}));

export const DateText = styled('p')(({ theme }) => ({
    opacity: '0.6',
    textAlign: 'end',
    fontSize: '12px',
    paddingBottom: '16px',
    paddingRight: '16px',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
        fontSize: '14px,'
    }
}));
